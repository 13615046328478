
import { computed, onMounted, onUnmounted, ref, toRef } from "vue";

import { useMessage }        from "@plugins/Messages-plugin";
import { messagesService }   from "@services/messages.service";
import ChatThread            from "@/modules/messages/components/ChatThread.vue";
import { useChatChannel }    from "@/modules/messages/logic/useChatChannel";
import { Thread }            from "@/model/ChatMessage";
import { PaginatedResponse } from "@/model/PaginatedResponse";

export default {
  components: { ChatThread },
  props     : {
    selectedThread: Object,
  },
  setup( _, { emit } ) {
    const { errorMessage } = useMessage();
    const selectedThread   = toRef( _, 'selectedThread' );

    function selectThread( thread: Thread ) {
      emit( "update:selectedThread", thread );
    }

    const isLoading     = ref<boolean>( false );
    const isLoadingMore = ref<boolean>( false );

    const paginatedThreads = ref<PaginatedResponse<Thread>>();
    const threads          = ref<Thread[]>( [] );
    const totalThreads     = computed( () => paginatedThreads.value?.total );

    async function loadThreads() {
      try {
        isLoading.value        = true;
        paginatedThreads.value = await messagesService.index( {
          per_page: 50,
          page    : 1,
        } );
        threads.value          = paginatedThreads.value.data;
      } catch (e) {
        console.error( e );
        errorMessage( "Caricamento Threads non riuscito" );
      } finally {
        isLoading.value = false;
      }
    }

    async function loadMoreThreads() {
      try {
        isLoadingMore.value = true;

        const per_page = paginatedThreads.value.per_page;
        const page     = paginatedThreads.value.current_page + 1;

        paginatedThreads.value = await messagesService.index( { per_page, page } );

        threads.value = [
          ...threads.value,
          ...paginatedThreads.value.data,
        ];
      } catch (e) {
        console.error( e );
        errorMessage( "Caricamento Threads non riuscito" );
      } finally {
        isLoadingMore.value = false;
      }
    }

    async function updateThreads() {
      /* Ricarico i threads*/
      paginatedThreads.value = await messagesService.index( {
        per_page: 50,
        page    : 1,
      } );

      threads.value = paginatedThreads.value.data;
    }

    function messageSentHandler( { data: { thread_id } } ) {
      if (selectedThread.value?.id !== thread_id) {
        updateThreads();
      }
    }

    function countChangeHandler( { data: { thread_id } } ) {
      if (selectedThread.value?.id !== thread_id) {
        updateThreads();
      }
    }

    const {
            subscribe,
            unsubscribe,
            addMessageSentListener,
            removeMessageSentListener,
            addCountChangeListener,
            removeCountChangeListener
          } = useChatChannel( messageSentHandler, countChangeHandler )

    onMounted( () => {
      subscribe();
      addMessageSentListener();
      addCountChangeListener();
      loadThreads();
    } )

    onUnmounted( () => {
      removeCountChangeListener();
      removeMessageSentListener();
      unsubscribe();
    } )

    return {
      selectThread,

      isLoading,
      totalThreads,

      isLoadingMore,
      loadMoreThreads,

      threads
    }
  }
}
