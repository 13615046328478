
import { computed, toRef } from "vue";

export default {
  name : "ChatAvatar",
  props: {
    username: String
  },
  setup( props ) {
    const username = toRef( props, 'username' )

    const initials = computed( () =>
        username.value
                ?.split( ' ' )
                .splice( 0, 3 )
                .map( word => word[0] )
                .join( '' )
                .toUpperCase()
    )

    return {
      initials
    }
  }
}
