import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5f288386")
const _hoisted_1 = { class: "page--limited minh0" }
const _hoisted_2 = { class: "card p-d-flex flex1 minh0" }
const _hoisted_3 = { class: "threads p-d-flex p-flex-column" }
const _hoisted_4 = { class: "chat p-d-flex p-flex-column" }
const _hoisted_5 = { class: "p-pl-3" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_ChatThreads = _resolveComponent("ChatThreads")!
  const _component_ChatMessages = _resolveComponent("ChatMessages")!
  const _component_ChatNewMessage = _resolveComponent("ChatNewMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeader, {
      showDelete: false,
      showSave: false,
      title: _ctx.$t('messages.title')
    }, null, 8, ["title"]),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("header", null, [
          _createElementVNode("h5", null, _toDisplayString(_ctx.$t('messages.threads_header')), 1)
        ]),
        _createVNode(_component_ChatThreads, {
          "selected-thread": _ctx.selectedThread,
          "onUpdate:selected-thread": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedThread = $event))
        }, null, 8, ["selected-thread"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.$t('messages.chat_with', {name: _ctx.chatWithName})), 1),
        _createVNode(_component_ChatMessages, {
          messages: _ctx.messages,
          thread: _ctx.selectedThread,
          isLoading: _ctx.isLoading
        }, null, 8, ["messages", "thread", "isLoading"]),
        (_ctx.selectedThread)
          ? (_openBlock(), _createBlock(_component_ChatNewMessage, {
              key: 0,
              modelValue: _ctx.newMessage,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.newMessage = $event)),
              isLoading: _ctx.isSending,
              onSubmit: _ctx.sendMessage
            }, null, 8, ["modelValue", "isLoading", "onSubmit"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}