
import { computed, defineComponent, nextTick, ref, toRef, watch } from "vue";

import ChatAvatar from "@/modules/messages/components/ChatAvatar.vue";

export default defineComponent( {
  name      : "ChatMessages",
  components: { ChatAvatar },
  props     : {
    messages : Array,
    thread   : Object,
    isLoading: Boolean,
  },
  setup( p ) {
    const thread = toRef( p, 'thread' )

    const entityName = computed( () => {
      const { name, surname } = thread.value?.entity1 || thread.value?.entity2
      return `${ name } ${ surname }`
    } )

    const messagesRef = ref();
    const messages    = toRef( p, 'messages' )
    watch( messages, onMessagesChange )

    function onMessagesChange() {
      nextTick( gotoLastMessage )
    }

    function gotoLastMessage() {
      messagesRef.value?.scrollTo( 0, messagesRef.value.scrollHeight );
    }

    return {
      entityName,
      messagesRef
    }
  }
} )
