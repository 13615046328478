import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-efa69880")
const _hoisted_1 = {
  key: 0,
  class: "p-px-3 flex1 minh0",
  style: {"overflow":"auto","width":"320px"}
}
const _hoisted_2 = {
  key: 1,
  class: "flex1 minh0",
  style: {"overflow":"auto"}
}
const _hoisted_3 = { class: "p-d-flex p-jc-center p-p-3" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_ChatThread = _resolveComponent("ChatThread")!
  const _component_Button = _resolveComponent("Button")!

  return ($setup.isLoading)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (m) => {
          return _createElementVNode("li", {
            key: `loader_${m}`,
            class: "p-d-flex p-ai-center w100 p-mb-2"
          }, [
            _createVNode(_component_Skeleton, {
              class: "p-mr-3",
              shape: "circle",
              size: "50px"
            }),
            _createVNode(_component_Skeleton, {
              height: "1.5rem",
              width: "13rem"
            })
          ])
        }), 64))
      ]))
    : (_openBlock(), _createElementBlock("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.threads, (thread) => {
          return (_openBlock(), _createBlock(_component_ChatThread, {
            key: `thread_${thread.id}`,
            thread: thread,
            class: _normalizeClass({ 'is_active' : thread.id === $props.selectedThread?.id}),
            onClick: ($event: any) => ($setup.selectThread(thread))
          }, null, 8, ["thread", "class", "onClick"]))
        }), 128)),
        _createElementVNode("li", null, [
          _createElementVNode("div", _hoisted_3, [
            (($setup.threads.length < $setup.totalThreads))
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  loading: $setup.isLoadingMore,
                  label: "Carica altri",
                  onClick: $setup.loadMoreThreads
                }, null, 8, ["loading", "onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
}