
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from "vue";

import { PageHeader }          from "@/components";
import ChatThreads             from "@/modules/messages/components/ChatThreads.vue";
import ChatMessages            from "@/modules/messages/components/ChatMessages.vue";
import { ChatMessage, Thread } from "@/model/ChatMessage";
import { messagesService }     from "@services/messages.service";
import { useMessage }          from "@plugins/Messages-plugin";
import ChatNewMessage          from "@/modules/messages/components/ChatNewMessage.vue";
import { useChatChannel }      from "@/modules/messages/logic/useChatChannel";

export default defineComponent( {
  components: {
    ChatNewMessage,
    ChatMessages,
    ChatThreads,
    PageHeader,
  },
  setup() {
    const isLoading        = ref<boolean>( false );
    const { errorMessage } = useMessage();

    const selectedThread = ref<Thread>();

    const chatWithName = computed( () => {
      if (!selectedThread.value) return "...";

      const { entity1, entity2 } = selectedThread.value
      const { name, surname }    = entity1 || entity2;
      return `${ name } ${ surname }`;
    } );

    watch( selectedThread, onThreadChange );

    function onThreadChange( thread: Thread ) {
      if (thread) {
        if (!thread.entity1) {
          thread.entity1_has_read = true;
        }

        if (!thread.entity2) {
          thread.entity2_has_read = true;
        }
      }

      loadMessages();
    }

    const messages = ref<ChatMessage[]>();

    async function loadMessages() {
      try {
        isLoading.value = true;
        messages.value  = null;
        messages.value  = await messagesService.threadMessages( selectedThread.value.id );
      } catch (e) {
        console.error( e );
        errorMessage( "Caricamento Threads non riuscito" );
      } finally {
        isLoading.value = false;
      }
    }

    const newMessage = ref<string>();

    const isSending = ref<boolean>( false );

    async function sendMessage() {
      try {
        isSending.value   = true;
        const sentMessage = await messagesService.sendMessageToThread(
            selectedThread.value.id,
            newMessage.value
        );

        messages.value = [
          ...(messages.value || []),
          sentMessage
        ];

        newMessage.value = "";
      } catch (e) {
        console.error( e );
        errorMessage( "Invio messaggio non riuscito" );
      } finally {
        isSending.value = false;
      }
    }

    const {
            subscribe,
            unsubscribe,
            addMessageSentListener,
            removeMessageSentListener
          } = useChatChannel( messageSentHandler )

    function messageSentHandler( { data } ) {
      if (
          /* Se il messaggio appartiene al thread corrente */
          selectedThread.value?.id === data.thread_id
          /* Se il messaggio non è stato inviato da me */
          && isSending.value === false
      ) {
        /* Aggiungo il nuovo messaggio alla lista */
        if (isSending.value === false) {
          messages.value = [
            ...(messages.value || []),
            data.message
          ];
          /* Avvio il caricamento dei messaggi a vuoto giusto per notificarne la lettura */
          messagesService.threadMessages( selectedThread.value.id )
        }
      }
    }

    onMounted( () => {
      subscribe();
      addMessageSentListener();
    } )

    onUnmounted( () => {
      removeMessageSentListener();
      unsubscribe();
    } )

    return {
      isLoading,
      chatWithName,
      selectedThread,
      messages,

      newMessage,
      isSending,
      sendMessage,
    }
  }
} )
