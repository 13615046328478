
import { computed, defineComponent, Ref, toRef } from "vue";

import { Thread }        from "@/model/ChatMessage";
import { DateFormatted } from "@sharedComponents";
import ChatAvatar        from "@/modules/messages/components/ChatAvatar.vue";

export default defineComponent( {
  components: {
    DateFormatted,
    ChatAvatar
  },

  props: {
    thread: Object as () => Thread,
  },

  setup( props ) {
    const thread = toRef( props, "thread" ) as Ref<Thread>;

    const userName = computed( () => {
      const { name, surname } = thread.value?.entity1 || thread.value?.entity2;
      return `${ name } ${ surname }`
    } );

    const businessName = computed( () => {
      const { business_name } = thread.value?.entity1 || thread.value?.entity2;
      return `${ business_name }`
    } );

    const hasToRead = computed( () => {
      const {
              entity1,
              entity2,
              entity1_has_read,
              entity2_has_read
            } = thread.value;

      if (!entity1) {
        return !entity1_has_read;
      }

      if (!entity2) {
        return !entity2_has_read;
      }

      return false;
    } )

    return {
      userName,
      businessName,
      hasToRead
    }
  }
} )
