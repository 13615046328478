import { ref }     from "vue";
import { Channel } from "pusher-js";

import { PusherChannelEnum, PusherEventsEnum, subscribeChannel } from "@/pusher";

export function useChatChannel(
    messageSentHandler: Function,
    countChangeHandler?: Function,
) {

    const chatChannel = ref<Channel>();

    function addMessageSentListener() {
        chatChannel.value.bind(
            PusherEventsEnum.MESSAGE_SENT,
            messageSentHandler
        )
    }

    function removeMessageSentListener() {
        chatChannel.value.unbind(
            PusherEventsEnum.MESSAGE_SENT,
            messageSentHandler
        );
    }

    function addCountChangeListener() {
        chatChannel.value.bind(
            PusherEventsEnum.COUNT_CHANGE,
            countChangeHandler
        )
    }

    function removeCountChangeListener() {
        chatChannel.value.unbind(
            PusherEventsEnum.COUNT_CHANGE,
            countChangeHandler
        );
    }

    function subscribe() {
        chatChannel.value = subscribeChannel( PusherChannelEnum.CHAT_ADMIN );
    }

    function unsubscribe() {
        chatChannel.value.unbind(
            PusherEventsEnum.MESSAGE_SENT,
            messageSentHandler
        );
    }

    return {
        addMessageSentListener,
        removeMessageSentListener,
        addCountChangeListener,
        removeCountChangeListener,
        subscribe,
        unsubscribe,
    }
}
