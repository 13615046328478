import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-43ca854c")
const _hoisted_1 = {
  key: 0,
  class: "p-px-3 flex1 minh0",
  style: {"overflow":"auto"}
}
const _hoisted_2 = { class: "message-from p-d-flex p-ai-center" }
const _hoisted_3 = { class: "message-to p-d-flex p-flex-row-reverse p-ai-center" }
const _hoisted_4 = {
  key: 1,
  ref: "messagesRef",
  class: "p-px-3 flex1 minh0",
  style: {"overflow":"auto"}
}
const _hoisted_5 = { class: "message" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_ChatAvatar = _resolveComponent("ChatAvatar")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (m) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: `loader_${m}`
          }, [
            _createElementVNode("li", _hoisted_2, [
              _createVNode(_component_Skeleton, {
                class: "p-mr-3",
                shape: "circle",
                size: "50px"
              }),
              _createVNode(_component_Skeleton, {
                width: `${Math.random()*90}%`,
                height: "2rem"
              }, null, 8, ["width"])
            ]),
            _createElementVNode("li", _hoisted_3, [
              _createVNode(_component_Skeleton, {
                class: "p-ml-3",
                shape: "circle",
                size: "50px"
              }),
              _createVNode(_component_Skeleton, {
                width: `${Math.random()*90}%`,
                height: "2rem"
              }, null, 8, ["width"])
            ])
          ], 64))
        }), 64))
      ]))
    : (_openBlock(), _createElementBlock("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message, i) => {
          return (_openBlock(), _createElementBlock("li", {
            key: `message_${message.id}_${i}`,
            class: _normalizeClass(message.created_entity_id ? 'message-from' : 'message-own')
          }, [
            (i === 0 || _ctx.messages[i-1].created_entity_id !== message.created_entity_id)
              ? (_openBlock(), _createBlock(_component_ChatAvatar, {
                  key: 0,
                  username: message.created_entity_id
                            ? _ctx.entityName
                            : 'T M T' 
                }, null, 8, ["username"]))
              : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_5, _toDisplayString(message.message), 1)
          ], 2))
        }), 128))
      ], 512))
}