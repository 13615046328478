<template>
  <form
      id="new_message"
      class="new-message"
      @submit.prevent="$emit('submit', newMessage)"
  >
    <div class="message-send">
      <Button :loading="isLoading"
              :title="$t('messages.send')"
              class="p-button-info"
              icon="pi pi-send"
              type="submit"
      />
    </div>
    <div class="message-input">
            <Textarea :modelValue="modelValue"
                      :placeholder="$t('messages.placeholder')"
                      required
                      style="resize: none;"
                      @keyup.enter="$emit('submit', newMessage)"
                      @update:modelValue="$emit('update:modelValue', $event)"/>
    </div>
  </form>
</template>

<script>
import {ref} from 'vue';

export default {
  name: "ChatNewMessage",
  emits: ['submit', 'update:modelValue'],
  props: ['modelValue', 'isLoading'],
  setup() {
    const newMessage = ref('');
    return {
      newMessage
    }
  }
}
</script>

<style lang="scss" scoped>
$dividerColor: #dee2e6;

.new-message {
  height: 50px;
  border-top: 1px solid $dividerColor;
  color: #afafc0;

  display: flex;

  .message-send {
    flex-basis: 50px;
    height: 100%;
    display: inline-grid;
    place-items: center;

    i {
      font-size: 24px;
    }
  }

  .message-input {
    flex: 1;
    display: inline-block;
    border-left: 1px solid $dividerColor;

    textarea {
      border: 0 none;
      font-size: 14px;
      width: 100%;
    }
  }
}
</style>
